// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-how-to-install-js": () => import("./../../../src/pages/how-to-install.js" /* webpackChunkName: "component---src-pages-how-to-install-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ps-js": () => import("./../../../src/pages/ps.js" /* webpackChunkName: "component---src-pages-ps-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-upgrade-js": () => import("./../../../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */)
}

